/* eslint-disable no-unused-vars */
import styles from "../css/app.css";
/* eslint-enable no-unused-vars */

// App main
const main = async () => {
  const { default: Vue } = await import(/* webpackChunkName: 'vue' */ "vue");
  const { default: axios } = await import(
    /* webpackChunkName: "axios" */ "axios"
  );
  const { default: Vuelidate } = await import(
    /* webpackChunkName: 'vuelidate' */ "vuelidate"
  );
  const { default: VueSkeletonLoading } = await import(
    /* webpackChunkName: 'vue-skeleton-loading' */ "vue-skeleton-loading"
  );

  // Add global instance of axios to Vue
  axios.defaults.headers.common = {
    "X-CSRF-Token": window.csrfToken,
    "X-Requested-With": "XMLHttpRequest",
  };
  axios.defaults.headers.post = {
    "Content-type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };
  Vue.prototype.$http = axios;

  // Apply global Vue addons
  Vue.use(Vuelidate);
  Vue.use(VueSkeletonLoading);

  /* eslint-disable no-unused-vars, new-cap */
  const Vm = new Vue({
    el: "#app",
    components: {
      checkoutSteps: () =>
        import(
          /* webpackChunkName: 'checkout-steps' */ "../vue/components/CheckoutSteps.vue"
        ),
      redemptionForm: () =>
        import(
          /* webpackChunkName: 'redemption-form' */ "../vue/RedemptionForm.vue"
        ),
      searchBar: () =>
        import(/* webpackChunkName: 'search-bar' */ "../vue/SearchBar.vue"),
      search: () =>
        import(/* webpackChunkName: 'search' */ "../vue/Search.vue"),
      faq: () => import(/* webpackChunkName: 'faq' */ "../vue/FAQ/index.vue"),
      curated: () =>
        import(/* webpackChunkName: 'curated' */ "../vue/Curated.vue"),
      orderForm: () =>
        import(/* webpackChunkName: 'orderForm' */ "../vue/OrderForm.vue"),
      orderFormHeartOfTheCity: () =>
        import(
          /* webpackChunkName: 'orderFormHeartOfTheCity' */ "../vue/OrderFormHeartOfTheCity.vue"
        ),
      contactForm: () =>
        import(/* webpackChunkName: 'contact-form' */ "../vue/ContactForm.vue"),
    },
    data: {},
    methods: {},
    mounted() {},
  });
};
/* eslint-enable no-unused-vars, new-cap */

main().then(() => {});
